<template>
  <div>
    <v-menu
      :close-on-content-click="closeOnContentClick"
      :disabled="!displayMessages"
      max-width="350"
      max-height="300"
      offset-y
      left
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="text-none"
          style="min-width: 40px !important;"
          color="transparent"
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            bordered
            :content="unreadMessageCount"
            :value="unreadMessageCount"
            overlap
            offset-x="10"
            offset-y="15"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
                id="support"
                v-bind="attrs"
                v-on="on"
                style="color: white; cursor: pointer;"
            >
              fa-regular fa-bell
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list
      dense
      two-line
      >
        <v-subheader
          class="font-weight-black d-flex align-center justify-space-between text-h5"
        >
          <div>
            Notifications
          </div>
          <div>
            <v-tooltip bottom>
              <span>Mark all read</span>
              <template #activator="{ on, attrs }">                    
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="$notificationPlugin.markAllRead()"
                >
                  <v-icon
                    small
                  >
                    fa-regular fa-eye
                  </v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              <span>Remove All Notifications</span>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="$notificationPlugin.removeAll()"
                >
                  <v-icon small>fa-regular fa-trash-can</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </v-subheader>
        <v-divider class="mt-0" />
        <template
          v-for="(message, index) in messages"
        >
          <v-list-item
            class="px-2"
            dense
            :key="message.id"
          >
            <v-list-item-content
              :class="`py-0 ${message.read ? 'grey--text text--lighten-2' : 'black--text text--lighten-2'}`"
            >
              <v-list-item-title
                class="font-weight-black d-flex align-center justify-space-between"
              >
                <div>
                  {{ formatDateTime(message.created) }}
                </div>
                <div>
                  <v-tooltip bottom>
                    <span>{{ `${message.read === true ? 'Mark as unread' : 'Mark as read'}` }}</span>
                    <template #activator="{ on, attrs }">                    
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="$notificationPlugin.toggleReadStatus(message.id)"
                      >
                        <v-icon
                          v-if="message.read"
                          small
                        >
                          fa-regular fa-eye-slash
                        </v-icon>
                        <v-icon
                          v-else
                          small
                        >
                          fa-regular fa-eye
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <span>Remove notification</span>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="$notificationPlugin.remove(message.id)"
                      >
                        <v-icon small>fa-regular fa-trash-can</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </div>
              </v-list-item-title>
              <v-list-item-content
                :class="`py-0 ${message.read ? 'grey--text text--lighten-2' : 'black--text'}`"
              >
                {{ message.message }} 
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index < messages.length - 1"
            :key="index"
            class="mt-0"
          />
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'FvNotificationList',
  data () {
    return {
      closeOnContentClick: false,
      displayMessages: false
    }
  },
  computed: {
    messages () {
      const notifications = this.$notificationPlugin.getNotifications('all', 'all')
      if (notifications.length > 0) {
        this.displayMessages = true
        return notifications
      } else {
        this.displayMessages = false
        return []
      }      
    },
    unreadMessageCount () {
      return this.$notificationPlugin.getNotifications('false', 'all').length
    }
  },
  methods: {
    formatDateTime (dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    }
  }
}
</script>

<style scoped>
</style>