import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAlert,{staticClass:"ml-1 text-left py-2",attrs:{"type":"error","dismissible":"dismissible","prominent":"","width":"100%"},on:{"input":function($event){return _vm.$notificationPlugin.markAllRead()}},model:{value:(_vm.displayAlerts),callback:function ($$v) {_vm.displayAlerts=$$v},expression:"displayAlerts"}},[_c('ul',{staticClass:"px-0"},_vm._l((_vm.visibleMessages),function(message,index){return _c(VListItem,{key:message.id,class:_vm.$vuetify.breakpoint.mdAndDown && index > 0 ? 'px-0 mt-3' : 'px-0',attrs:{"dense":""}},[_c(VListItemContent,{staticClass:"text-body-1 py-0"},[_c(VRow,{staticClass:"text-align-center"},[_c(VCol,{attrs:{"cols":"10","lg":"11"}},[_vm._v(" "+_vm._s(message.message)+" ")]),_c(VCol,{staticClass:"px-0",attrs:{"cols":"2","lg":"1"}},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$notificationPlugin.toggleReadStatus(message.id)}}},[(message.read)?_c(VIcon,{attrs:{"small":""}},[_vm._v(" fa-regular fa-eye-slash ")]):_c(VIcon,{attrs:{"small":""}},[_vm._v(" fa-regular fa-eye ")])],1),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$notificationPlugin.remove(message.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" fa-regular fa-trash-can ")])],1)],1)],1)],1)],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }