<template>
  <span
    v-if="unsyncedItems > 0 && !taskSyncExecuting"
    class="pr-3"
    style="height: 100%; display: flex; align-items: center;"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="pa-0"
          color="transparent"
          small
          min-width="0"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-badge
            :content="unsyncedItems"
            :value="true"
            color="transparent"
            class="syncBadge"
          >
            <v-icon
              color="white"
              size="24"
            >
              fa-regular fa-circle
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>Data Not Synced</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      width="auto"
      persistent
    >
      <v-card class="text-left">
        <v-card-title class="v-dialogHeader text-left px-3 py-1">
          Data Not Synced
        </v-card-title>
        <v-card-text
          class="px-3 py-3 formDataDialog"
          style="max-height: 70vh; overflow: auto"
        >
          <span
            v-for="(task, index) in unsyncedTasks"
            :key="index"
          >
            <h3
              v-if="index === 0"
              class="mb-2"
            >
              Forms
            </h3>
            <p>
              <b>Name:</b> {{ taskDefinitions.find(def => def.Id === task.FormData.fvTaskId).Name }}
            </p>
            <p>
              <b>ID:</b> {{ task.SnId }}
            </p>
            <p>
              <b>Task Date:</b> {{ formatDateShort(task.FormData.taskDate) }}
            </p>
            <p>
              <b>Status:</b> {{ task.Submitted === 2 ? 'Deleted' : statusTextConversion[task.Status] }}
            </p>
            <p>
              <b>Last Modified:</b> {{ formatDate(task.FormData.dateSaved) }}
            </p>
            <p v-if="task.Submitted === 2">
              <b>Action:</b> Deleted
            </p>
            <p v-else>
              <b>Action:</b> {{ task.SnId === '' ? 'Created' : 'Updated' }}
            </p>
            <v-divider
              v-if="index !== unsyncedTasks.length - 1 || unsyncedAttachments.length > 0"
              class="my-3"
            />
          </span>
          <span
            v-for="(attach, index) in unsyncedAttachments"
            :key="'A' + index"
          >
            <h3
              v-if="index === 0"
              class="mb-2"
            >
              Attachments
            </h3>
            <p>
              <b>Name:</b> {{ taskDefinitions.find(def => def.SNTaskId === attach.ParentForm.TaskDefinitionId).Name }}
            </p>
            <p>
              <b>ID:</b> {{ attach.SnId }}
            </p>
            <p>
              <b>Task Date:</b> {{ attach.ParentForm ? formatDateShort(attach.ParentForm.FormData.taskDate) : '' }}
            </p>
            <p>
              <b>Section:</b> {{ formatAttachmentSection(attach.Section) }}
            </p>
            <p>
              <b>Created:</b> {{ formatDate(attach.CreatedOn) }}
            </p>
            <p>
              <b>Action:</b> {{ attach.Submitted === 2 ? 'Deleted' : 'Created' }}
            </p>
            <v-divider
              v-if="index !== unsyncedAttachments.length - 1"
              class="my-3"
            />
          </span>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { referenceDataMixin } from '@/mixins/referenceDataMixin'
import { DateTime } from 'luxon'
export default {
  name: 'SyncBadge',
  mixins: [referenceDataMixin],
  data () {
    return {
      statusTextConversion: {
        in_progress: 'In Progress',
        completed: 'Completed',
      },
      taskDefinitions: null,
      dialog: false,
    }
  },
  computed: {
    ...mapState({
      deletedAttachments: (state) => state.applicationCoreStore.deletedAttachments,
      deletedForms: (state) => state.applicationCoreStore.deletedForms,
      taskSyncExecuting: (state) => state.applicationCoreStore.taskSyncExecuting,
      unsubmittedAttachments: (state) => state.applicationCoreStore.unsubmittedAttachments,
      unsubmittedForms: (state) => state.applicationCoreStore.unsubmittedForms,
    }),
    unsyncedAttachments () {
      const unmappedAttachmentArray = this.unsubmittedAttachments.concat(this.deletedAttachments)
      const mappedAttachmentArray = unmappedAttachmentArray.map(attach => attach.Id)
      const duplicates = mappedAttachmentArray.filter((attach, index) => {
        return mappedAttachmentArray.indexOf(attach) !== index
      })
      duplicates.forEach(dupe => {
        const index = unmappedAttachmentArray.findIndex(attach => attach.Id === dupe)
        unmappedAttachmentArray.splice(index, 1)
      })
      unmappedAttachmentArray.forEach(attach => {
        if (this.unsyncedTasks) {
          attach.ParentForm = this.unsyncedTasks.find(form => form.Id === attach.ParentId)
        }
      })
      unmappedAttachmentArray.sort((a, b) => {
        return a.CreatedOn && b.CreatedOn ? DateTime.fromFormat('yyyy-MM-dd hh:mm:ss a', a.CreatedOn).ts - DateTime.fromFormat('yyyy-MM-dd hh:mm:ss a', b.CreatedOn).ts : -1
      })
      return unmappedAttachmentArray
    },
    unsyncedItems () {
      return this.unsyncedTasks.length + this.unsyncedAttachments.length
    },
    unsyncedTasks () {
      const unmappedFormArray = this.unsubmittedForms.concat(this.deletedForms)
      const mappedFormArray = unmappedFormArray.map(form => form.Id)
      const duplicates = mappedFormArray.filter((form, index) => {
        return mappedFormArray.indexOf(form) !== index
      })
      // Filters out duplicates when a form exists in both Deleted and Unsubmitted arrays
      duplicates.forEach(dupe => {
        const index = unmappedFormArray.findIndex(form => form.Id === dupe)
        unmappedFormArray.splice(index, 1)
      })

      unmappedFormArray.sort((a, b) => {
        return DateTime.fromFormat('yyyy-MM-dd hh:mm:ss a', a.FormData.dateSaved).ts - DateTime.fromFormat('yyyy-MM-dd hh:mm:ss a', b.FormData.dateSaved).ts
      })
      return unmappedFormArray
    },
  },
  watch: {
    taskSyncExecuting (newValue) {
      if (newValue) {
        this.dialog = false
      }
    },
    unsyncedItems (newValue) {
      if (newValue === 0) {
        this.dialog = false
      }
    },
  },
  async mounted () {
    this.taskDefinitions = await this.getTaskDefinitions()
  },
  methods: {
    formatAttachmentSection (section) {
      return section.replace('Photo', '')
    },
    formatDate (date) {
      return date ? DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss a').toFormat('MM/dd/yyyy, h:mm:ss a') : ''
    },
    formatDateShort (date) {
      let formattedDate = DateTime.fromFormat(date, 'yyyy-MM-dd h:mm:ss a').toFormat('MM/dd/yyyy, h:mm:ss a')
      if (formattedDate === 'Invalid DateTime') {
        formattedDate = DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy')
      }
      return date ? formattedDate : ''
    },
  },
}
</script>

<style lang="sass">
.formDataDialog
  span
    p
      margin-bottom: 2px
      display: grid
      grid-template-columns: 2fr 3fr
      b
        font-weight: bold
</style>
